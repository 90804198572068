import {bgBase64Example} from './bgBase64Example';

const tensorFlowBodyPixInit = async (type = 'blurBg', bgBase64) => {
    const bodyPix = window.bodyPix;
    const tf = window.tf;

    const canvasEl = document.createElement('canvas');
    const webcamEl = document.createElement('video');

    const ctx = canvasEl.getContext('2d');

    const image = new Image();
    image.src = bgBase64 || bgBase64Example;

    const streamWrapper = async (stream) => {
        async function blurBg() {
            webcamEl.srcObject = stream;
            webcamEl.play();
            const net = await bodyPix.load({
                architecture: 'ResNet50',
                outputStride: 32,
                quantBytes: 4,
            });

            const backgroundBlurAmount = 10;
            const edgeBlurAmount = 1;
            const flipHorizontal = false;

            async function renderLoop(now, metadata) {
                if (!metadata?.width || !metadata?.height) {
                    webcamEl.requestVideoFrameCallback(renderLoop);
                    return;
                }

                webcamEl.width = metadata?.width;
                webcamEl.height = metadata?.height;

                const segmentationGlobal = await net.segmentPerson(webcamEl, {
                    flipHorizontal,
                });

                canvasEl.width = metadata?.width;
                canvasEl.height = metadata?.height;

                bodyPix.drawBokehEffect(
                    canvasEl,
                    webcamEl,
                    segmentationGlobal,
                    backgroundBlurAmount,
                    edgeBlurAmount,
                    flipHorizontal
                );
                webcamEl.requestVideoFrameCallback(renderLoop);
            }

            renderLoop();

            return canvasEl.captureStream(30);
        }

        async function customBg() {
            webcamEl.srcObject = stream;
            webcamEl.play();
            const net = await bodyPix.load({
                architecture: 'ResNet50',
                outputStride: 32,
                quantBytes: 4,
            });

            async function renderLoop(now, metadata) {
                if (!metadata?.width || !metadata?.height) {
                    webcamEl.requestVideoFrameCallback(renderLoop);
                    return;
                }

                webcamEl.width = metadata?.width;
                webcamEl.height = metadata?.height;

                const segmentation = await net.segmentPerson(webcamEl, {
                    flipHorizontal: false,
                });

                canvasEl.width = metadata?.width;
                canvasEl.height = metadata?.height;

                // 2. Creating a Background Mask
                const background = {r: 0, g: 0, b: 0, a: 0};
                const mask = bodyPix.toMask(segmentation, background, {r: 0, g: 0, b: 0, a: 255});

                if (mask) {
                    ctx.putImageData(mask, 0, 0);
                    ctx.globalCompositeOperation = 'source-in';

                    // 3. Drawing the Background
                    ctx.drawImage(image, 0, 0, canvasEl.width, canvasEl.height);

                    // Draw the mask (segmentation)
                    ctx.globalCompositeOperation = 'destination-over';
                    ctx.drawImage(webcamEl, 0, 0, canvasEl.width, canvasEl.height);
                    ctx.globalCompositeOperation = 'source-over';

                    // Add a delay to control the frame rate (adjust as needed) less CPU intensive
                    // await new Promise((resolve) => setTimeout(resolve, 100));

                    // Continue updating the canvas
                    webcamEl.requestVideoFrameCallback(renderLoop);
                }
            }

            renderLoop();

            return canvasEl.captureStream(30);
        }

        if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
            await tf.setBackend('webgl');

            if (type === 'customBg') {
                return await customBg();
            }

            return await blurBg();
        } else {
            alert('API not supported');
        }
    };

    return new Promise((resolve) => {
        image.onload = () => {
            resolve(streamWrapper);
        };
    });
};

export {tensorFlowBodyPixInit};
