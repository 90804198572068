import {useEffect, useState} from 'react';

import {MHButton, MHSwitchWithLabels} from '@/components/base';

import {registerTensorFlow, unregisterTensorFlow} from './utils';

import styles from './videoCall.module.scss';

interface SettingsProps {
    onClose: () => void;
}

export default function Settings({onClose}: SettingsProps) {
    const [tensorBlur, setTensorBlur] = useState(false);
    const [curBgImage, setCurBgImage] = useState<string | null>(null);
    const [tensorCustomBg, setTensorCustomBg] = useState(false);
    useEffect(() => {
        const tensorBlurLocal = localStorage.getItem('tensorBlur');
        setTensorBlur(tensorBlurLocal === 'true');

        const tensorCustomBg = localStorage.getItem('tensorCustomBg');
        setTensorCustomBg(tensorCustomBg === 'true');

        const customBgImage = localStorage.getItem('customBgSrcBase64');
        setCurBgImage(customBgImage);
    }, []);

    return (
        <div className={styles.settingsWrapper}>
            <div className={styles.settings}>
                <span
                    style={{
                        color: 'white',
                        fontSize: '20px',
                        paddingBottom: '20px',
                    }}
                >
                    Settings
                </span>

                <span
                    style={{
                        color: 'white',
                        fontSize: '16px',
                    }}
                >
                    Blurring
                </span>
                <MHSwitchWithLabels
                    checked={tensorBlur}
                    checkedLabel="On"
                    unCheckedLabel="Off"
                    onChange={() => {
                        if (tensorBlur) {
                            unregisterTensorFlow();
                            setTensorBlur(false);
                            localStorage.setItem('tensorBlur', 'false');
                        } else {
                            registerTensorFlow('blurBg');
                            setTensorBlur(true);
                            setTensorCustomBg(false);
                            localStorage.setItem('tensorBlur', 'true');
                            localStorage.setItem('tensorCustomBg', 'false');
                        }
                    }}
                />
                <span
                    style={{
                        marginTop: '20px',
                        color: 'white',
                        fontSize: '12px',
                    }}
                >
                    Custom BG
                </span>
                <MHSwitchWithLabels
                    checked={tensorCustomBg}
                    checkedLabel="On"
                    unCheckedLabel="Off"
                    onChange={() => {
                        if (tensorCustomBg) {
                            unregisterTensorFlow();
                            setTensorCustomBg(false);
                            localStorage.setItem('tensorCustomBg', 'false');
                        } else {
                            registerTensorFlow('customBg');
                            setTensorCustomBg(true);
                            setTensorBlur(false);
                            localStorage.setItem('tensorCustomBg', 'true');
                            localStorage.setItem('tensorBlur', 'false');
                        }
                    }}
                />
                <span
                    style={{
                        marginTop: '20px',
                        color: 'white',
                        fontSize: '12px',
                    }}
                >
                    Upload custom BG
                </span>
                <input
                    id="customBgUploader"
                    type="file"
                    accept="image/*"
                    style={{display: 'none'}}
                    onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (!file) {
                            return;
                        }
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            const data = e.target?.result;
                            localStorage.setItem('customBgSrcBase64', data as string);
                            unregisterTensorFlow();
                            localStorage.setItem('tensorBlur', 'false');
                            localStorage.setItem('tensorCustomBg', 'true');
                            setCurBgImage(data as string);
                            registerTensorFlow('customBg', data);
                        };
                        reader.readAsDataURL(file);
                    }}
                />
                <div
                    style={{
                        display: 'flex',
                        gap: '10px',
                        marginTop: '10px',
                    }}
                >
                    {curBgImage && (
                        <img
                            src={curBgImage}
                            alt="custom bg"
                            style={{
                                width: '100px',
                                height: '100px',
                                objectFit: 'cover',
                            }}
                        />
                    )}
                </div>
                <div style={{display: 'flex', gap: '10px'}}>
                    <MHButton
                        buttonType="danger"
                        onClickHandler={() => {
                            localStorage.removeItem('customBgSrcBase64');
                            unregisterTensorFlow();
                            setCurBgImage(null);
                            registerTensorFlow('customBg');
                        }}
                        text="Remove custom BG"
                    />
                    <MHButton
                        buttonType="primary"
                        onClickHandler={() => {
                            const el = document.getElementById('customBgUploader');

                            el?.click();
                        }}
                        text="Upload custom BG"
                    />
                </div>
            </div>
            <MHButton
                className={styles.settingsButton}
                onClickHandler={() => {
                    onClose();
                }}
                text="Close"
            />
        </div>
    );
}
