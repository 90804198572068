import {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import classNames from 'classnames';

import {MHLogoIcon, MHLogoWhiteIcon} from '@/assets/icons';
import {MHToolTip, MHTypography} from '@/components/base';
import ErrorBoundary from '@/components/base/MHErrorBoundary';
import routesStatic from '@/constants/routes';
import {selectCurrentCallMeetingId} from '@/redux/commonCalls/selectors';
import {videoProvider} from '@/services/videoService';

import {ChangeMissionControl} from '../ChangeMissionControl';
import {
    missionControlIdsSelector,
    phoneCallDataSelector,
    selectClinicName,
    urgentAlertsPermissionsSelector,
} from './redux/selectors';
import {getInitialData} from './redux/slice';
import {updateVideoNotifications} from './redux/softDependencies';
import {AGENT_STATUSES} from './shared/constants';
import {ActiveMeetingsList} from './ActiveMeetingsList';
import {AvailabilityStatusSwitcher} from './AvailabilityStatusSwitcher';
import {CallHandler} from './CallHandler';
import {LastViewedPatients} from './LastViewedPatients';
import {RightSideMenu} from './RightSideMenu';
import {UrgentAlerts} from './UrgentAlerts';
import {VideoCall} from './VideoCall';

import styles from './header.module.scss';

export const MHHeader = () => {
    const dispatch = useDispatch();
    const {isOutboundCallActive, awsConnectAgentStatus} = useSelector(phoneCallDataSelector, shallowEqual);
    const missionControlIds = useSelector(missionControlIdsSelector);
    const urgentAlertsAvailable = useSelector(urgentAlertsPermissionsSelector);
    const clinicName = useSelector(selectClinicName);
    const currentCallMeetingId = useSelector(selectCurrentCallMeetingId);

    const isActive = currentCallMeetingId || isOutboundCallActive;

    useEffect(() => {
        dispatch(getInitialData());
    }, [dispatch]);

    useEffect(() => {
        // aws always initializes with "offline" state, so we have to check manually
        // if we should be on call and prevent stopping zoom and clearing polling
        const storedMeetingId = sessionStorage.getItem('meetingId');

        if (awsConnectAgentStatus === AGENT_STATUSES.OFFLINE || awsConnectAgentStatus === AGENT_STATUSES.AVAILABLE) {
            if (!storedMeetingId) {
                videoProvider.clearVideoCallStatusPollingId();
            }
        }

        if (awsConnectAgentStatus === AGENT_STATUSES.ON_VIDEO_CALL) {
            videoProvider.clearIncomingMeetingsShortPolling();
            dispatch(updateVideoNotifications([]));
        }

        const startZoom = () => {
            videoProvider.setHistory(history);
            videoProvider.initiateIncomingMeetingsShortPolling();
        };

        const stopZoom = () => {
            videoProvider.clearIncomingMeetingsShortPolling();
            videoProvider.clearVideoCallStatusPollingId();
        };

        awsConnectAgentStatus === AGENT_STATUSES.OFFLINE && !storedMeetingId && stopZoom();
        awsConnectAgentStatus === AGENT_STATUSES.AVAILABLE && startZoom();
    }, [awsConnectAgentStatus, dispatch]);

    return (
        <ErrorBoundary>
            <AppBar
                className={`
                    ${styles.general}
                    ${isActive && styles.callActive}  
                    ${awsConnectAgentStatus === AGENT_STATUSES.OFFLINE && styles.offline} 
                `}
            >
                <Toolbar className={styles.toolBar}>
                    <Link to={routesStatic.HOMEPAGE}>
                        {isActive ? (
                            <MHLogoWhiteIcon className={styles.logo} />
                        ) : (
                            <MHLogoIcon className={styles.logo} />
                        )}
                    </Link>
                    {clinicName && (
                        <span className={styles.clinicNameWrapper}>
                            <MHToolTip tooltipContent={clinicName} light>
                                <MHTypography variant="headline5" color={isActive ? 'white' : null} noWrap>
                                    {clinicName}
                                </MHTypography>
                            </MHToolTip>
                        </span>
                    )}

                    <Grid
                        className={styles.toolbarWrapper}
                        container
                        spacing={0}
                        justifyContent="flex-end"
                        alignItems="center"
                        wrap="nowrap"
                        width="unset"
                        marginLeft="auto"
                    >
                        <VideoCall />
                        {window.DISABLE_AWS_LOGIN === 'true' && 'Video Calls Only'}
                        {(!window.DISABLE_AWS_LOGIN || window.DISABLE_AWS_LOGIN !== 'true') && <CallHandler />}
                        <ActiveMeetingsList classes={styles} />
                        <Grid
                            className={classNames({
                                [styles.gridBorder]: missionControlIds.length > 1,
                            })}
                            item
                        >
                            <ChangeMissionControl />
                        </Grid>
                        <Grid className={styles.gridBorder} item>
                            {urgentAlertsAvailable && <UrgentAlerts />}
                            <LastViewedPatients />
                        </Grid>
                        <Grid className={styles.gridBorder} item>
                            <AvailabilityStatusSwitcher />
                        </Grid>
                        <RightSideMenu />
                    </Grid>
                </Toolbar>
            </AppBar>
        </ErrorBoundary>
    );
};
