import {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {usePrevious} from 'react-use';
import CallIcon from '@mui/icons-material/Call';
import SettingsIcon from '@mui/icons-material/Settings';
import ShareIcon from '@mui/icons-material/Share';
import classNames from 'classnames';
import clsx from 'clsx';

import {VIDEO_PROVIDER_SETTINGS, videoProvider} from '@/services/videoService';
import copyToClipboard from '@/utils/copyToClipboard';
import {generalNotify} from '@/utils/notifyMessages';

import Settings from './Settings';

import './styles.css';
import styles from './videoCall.module.scss';

type VideoCallWidgetProps = {
    currentMeetingId: string;
    user: {
        firstName: string;
        id: number;
        lastName?: string;
    };
    onClose?: () => void;
    isForceLoadingState?: boolean;
    newWindowRef?: React.MutableRefObject<Window | null>;
    onDisconnect?: () => void;
};

export const VideoCallWidget = ({
    currentMeetingId: currentCallMeetingId,
    user,
    onClose,
    onDisconnect,
    isForceLoadingState = false,
}: VideoCallWidgetProps) => {
    const ref = useRef<HTMLIFrameElement>(null);
    const prevMeetingId = usePrevious(currentCallMeetingId);
    const [showSettings, setShowSettings] = useState(false);
    const handleClose = useCallback(() => {
        onClose?.();
        window.close();
    }, [onClose]);
    const handleDisconnect = useCallback(() => {
        onDisconnect?.();
        window.close();
    }, [onDisconnect]);

    useEffect(() => {
        const connectionStatus = document.getElementById('connectionStatus');

        if (isForceLoadingState) {
            connectionStatus?.classList.add('loading');
        }
    }, [isForceLoadingState]);

    useLayoutEffect(() => {
        if (prevMeetingId === currentCallMeetingId) return;

        if ('initVidyoService' in window) {
            initVidyoService?.({
                roomKey: currentCallMeetingId,
                autoJoin: '1',
                displayName: `${user?.firstName} pid${user?.id}`,
                onClose: handleClose,
                onDisconnected: handleDisconnect,
            });
        }
    }, [
        currentCallMeetingId,
        handleClose,
        prevMeetingId,
        user?.firstName,
        user?.id,
        user.lastName,
        onDisconnect,
        handleDisconnect,
    ]);

    return (
        <div
            id="videoCallWidget"
            ref={ref}
            className={styles.root}
            style={{
                visibility: 'visible',
                display: 'block',
                top: 0,
                left: 0,
                overflow: 'hidden',
                zIndex: 1000,
                pointerEvents: 'auto',
                width: `100%`,
                height: `100%`,
                minHeight: `100%`,
                minWidth: `100%`,
                backgroundColor: 'black',
            }}
        >
            {showSettings && (
                <Settings
                    onClose={() => {
                        setShowSettings(false);
                    }}
                />
            )}
            <div id="vidyoConnector" className={styles.vidyoConnector}>
                <div id="connectionStatus" className="loading">
                    <div className="connecting">
                        <CallIcon
                            className={classNames(styles.loadingIcon)}
                            style={{color: 'white', fontSize: '80px'}}
                        />
                        <div style={{color: 'white'}}>Connecting...</div>
                    </div>
                </div>
                <div id="renderer-container" className={clsx(styles.rendererContainer, 'renderer-container')}>
                    <div id="renderer" className={styles.renderer}></div>
                </div>
                <div className={styles.toolbar}>
                    <div id="toolbarCenter" className={styles.toolbarCenter}>
                        <button
                            id="cameraButton"
                            title="Camera Privacy"
                            className={classNames(styles.toolbarButton, 'cameraOn')}
                        ></button>
                        <button
                            id="joinLeaveButton"
                            title="Join Conference"
                            className={classNames(styles.toolbarButton, 'callStart')}
                        ></button>
                        <button
                            id="microphoneButton"
                            title="Microphone Privacy"
                            className={classNames(styles.toolbarButton, 'microphoneOn')}
                        ></button>
                        <button
                            id="optionsButton"
                            title="Copy Meeting Link"
                            className={classNames(styles.toolbarButton)}
                            onClick={() => {
                                copyToClipboard(
                                    videoProvider.getSharedLink({
                                        meetingId: currentCallMeetingId,
                                        portalUrl: VIDEO_PROVIDER_SETTINGS.VIDYO.portalUrl,
                                    })
                                )
                                    .then(() => {
                                        generalNotify({
                                            title: 'Success',
                                            message: 'Meeting link copied to clipboard',
                                            status: 'success',
                                        });
                                    })
                                    .catch((err) => {
                                        console.error(err);
                                    });
                            }}
                        >
                            <ShareIcon
                                style={{
                                    color: 'white',
                                    cursor: 'pointer',
                                    fontSize: '30px',
                                }}
                            />
                        </button>
                        <button
                            className={classNames(styles.toolbarButton)}
                            onClick={() => {
                                setShowSettings(true);
                            }}
                        >
                            <SettingsIcon />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
