import {tensorFlowBodyPixInit} from './tensorFlowBodyPix';

declare global {
    interface Window {
        vidyoConnector: any;
    }
}

async function registerTensorFlow(type: 'customBg' | 'blurBg', customBg?: string | ArrayBuffer | null) {
    const steamWrapper = await tensorFlowBodyPixInit(type, customBg);

    window.vidyoConnector.RegisterLocalCameraStreamInterceptor(steamWrapper);
}

function unregisterTensorFlow() {
    return window.vidyoConnector.UnregisterLocalCameraStreamInterceptor();
}

export {registerTensorFlow, unregisterTensorFlow};
