import {useCallback, useEffect, useRef, useState} from 'react';

import {registerTensorFlow} from '@/components/general/MHHeader/VideoCall/utils';
import {VideoCallWidget} from '@/components/general/MHHeader/VideoCall/VideoCallWidget';

const vidyoConnectorSrc = '/VidyoConnectorWidget.js';
const jquerySrc = '/jquery-3.1.1.min.js';

export const VideoCall = () => {
    const intervalRef = useRef<ReturnType<typeof setInterval>>(null);
    const [isReady, setIsReady] = useState(false);
    const endCallRef = useRef<ReturnType<typeof setTimeout>>(null);
    const meetingId = localStorage.getItem('meetingId');
    const userJson = localStorage.getItem('user');
    const user = JSON.parse(userJson) as {
        firstName: string;
        lastName: string;
        id: number;
    };

    useEffect(() => {
        const scriptJquery = document.createElement('script');
        const scriptVidyoConnector = document.createElement('script');

        scriptJquery.src = jquerySrc;
        scriptJquery.async = false;

        scriptVidyoConnector.src = vidyoConnectorSrc;
        scriptVidyoConnector.async = false;

        document.body.appendChild(scriptJquery);
        document.body.appendChild(scriptVidyoConnector);

        scriptVidyoConnector.onload = () => {
            setIsReady(true);
        };

        return () => {
            scriptJquery && document.body.removeChild(scriptJquery);
            scriptVidyoConnector && document.body.removeChild(scriptVidyoConnector);
        };
    }, []);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            console.info('vidyoConnector init bg effects interval');
            if ('vidyoConnector' in window && window?.vidyoConnector?.RegisterLocalCameraStreamInterceptor) {
                const tensorBlur = localStorage.getItem('tensorBlur');
                const tensorCustomBg = localStorage.getItem('tensorCustomBg');

                if (tensorBlur === 'true') {
                    registerTensorFlow('blurBg');
                    clearInterval(intervalRef.current);
                    return;
                }

                if (tensorCustomBg === 'true') {
                    const customBgImage = localStorage.getItem('customBgSrcBase64');
                    registerTensorFlow('customBg', customBgImage);
                    clearInterval(intervalRef.current);
                    return;
                }
            }
        }, 1000);

        return () => {
            clearInterval(intervalRef.current);
        };
    }, []);

    const handleDisconnect = useCallback(() => {
        if (!('vidyoConnector' in window)) {
            return;
        }

        vidyoConnector?.Disconnect?.();
        vidyoConnector?.SetCameraPrivacy?.({
            privacy: true,
        });
        vidyoConnector?.SetMicrophonePrivacy?.({
            privacy: true,
        });
    }, []);

    const handleEndCall = useCallback(() => {
        localStorage.removeItem('meetingId');
        localStorage.removeItem('user');

        clearTimeout(endCallRef.current);
        window.close?.();

        endCallRef.current = setTimeout(() => {
            handleDisconnect();
        }, 1000);
    }, [handleDisconnect]);

    return (
        <>
            {isReady && (
                <VideoCallWidget
                    currentMeetingId={meetingId}
                    onClose={handleEndCall}
                    onDisconnect={handleDisconnect}
                    user={{
                        firstName: user.firstName,
                        lastName: user?.lastName,
                        id: user?.id,
                    }}
                />
            )}
        </>
    );
};
